<template>
  <div class="login">
    <div class="box">
      <img :src="logo || require('/src/assets/images/logo.png')">
      <el-form
        ref="form"
        :model="form"
      >
        <el-form-item>
          <el-input
            v-model="form.groupId"
            placeholder="账号"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.groupPassword"
            type="password"
            placeholder="密码"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            @click="onSubmit"
            class="submit"
          >
            登 录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import systemConfig from '../monitor/systemConfig'
export default {
  name: 'LoginAA',
  data () {
    return {
      logo: systemConfig.logo,
      form: {}
    }
  },
  computed: {
    logoBg () {
      if (this.logo) return `background-image: url('${this.logo}')`
      return ''
    }
  },
  created () {
    const userInfo = this.$root.libs.data.getStorage('userInfo')
    if (userInfo && JSON.parse(userInfo).jwt) this.$router.push('/monitor')
  },
  methods: {
    async onSubmit () {
      const res = await this.$root.libs.request(
        this.$root.libs.api.ssyypt.training_recipe_running_web
          .loginByStudioAuthCodeAndPassword,
        this.form
      )
      if (res.data) {
        this.$root.libs.data.setStorage('userInfo', {
          ...res.data.data,
          jwt: res.data.jwt
        })

        this.$router.push('/monitor')
      } else this.$message.error('账号或密码错误')
    }
  }
}
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  background-image: radial-gradient(#5ca5f5, #157ddc);
  .box {
    width: 20rem;
    margin: auto;
    text-align: center;
    img {
      width: 75%;
      margin-bottom: 2rem;
    }
    .submit {
      width: 100%;
      background-color: yellow;
      border: none;
    }
  }
}
</style>
