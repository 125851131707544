const systemConfig = {
  //   logo: "https://bce.bdstatic.com/logo/logo@2x.png",
  nav: [
    {
      label: '实时状态',
      icon: 'icon-fund-fill',
      url: 'equipment',
      name: 'equipment',
      time: 23 * 1000
    },
    {
      label: '运行状况',
      icon: 'icon-dashboard-fill',
      url: 'state',
      name: 'state',
      time: 11 * 1000
    },
    {
      label: '使用趋势',
      icon: 'icon-areachart',
      url: 'trends',
      name: 'trends',
      time: 9 * 1000
    },
    {
      label: '课题相关',
      icon: 'icon-read',
      url: 'class',
      name: 'class',
      time: 7 * 1000
    }
  ]
}
export default systemConfig
